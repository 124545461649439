import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import QR from './QR';

function App() {
  const [dinner, setDinner] = useState(0);

  return (
    <div className="App">
      <header className="App-header">
        <QR dinner={dinner} className="App-logo" />
        <div>
          {[0, 1, 2].map(newDinner => (
            <button type="button" onClick={() => setDinner(newDinner)}>
              {newDinner}x eten
            </button>
          ))}
        </div>
      </header>
    </div>
  );
}

export default App;
