import QRCode from "react-qr-code";

const urls = [
    'https://bunq.me/o/1Ot64bQJQNmAZvyh0JI3j0',
    'https://bunq.me/o/6VKZPiVcyxEST2bZAmiUUD',
    'https://bunq.me/o/4lBvO40OpMV84ZpPS5PDjn',
];

export default function QR({ dinner = 0 }) {
    const url = urls[dinner];

    return (<div>
            <QRCode value={url} />
            <p>Deelname {dinner > 0 && `+ ${dinner}x eten`}</p>
        </div>);
}